// imports
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

// Variables
// fonts
$FONT_FAMILY_REGULAR_1: 'Inter', 'HelveticaNeue-Light', 'Helvetica Neue Light',
  'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

$FONT_SIZE_1: 0.65rem;
$FONT_SIZE_2: 0.85rem;
$FONT_SIZE_3: 1rem;

$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_BOLD: 600;
$FONT_COLOR_BLACK: #1a1a1a;

// colors
$WHITE: rgba(255, 255, 255, 1);
$BLACK: #1a1a1a;

$GREY1: rgba(230, 230, 230, 1);
$GREY2: rgba(210, 210, 210, 1);
$GREY3: rgba(190, 190, 190, 1);
$GREY4: rgba(160, 160, 160, 1);
$GREY5: rgba(120, 120, 120, 1);
$GREY6: rgba(51, 51, 51, 1);

// neumorphism light
$BG1A1: rgba(247, 249, 253, 1);
$BG1A2: rgba(255, 255, 255, 1);
$BG1B1: rgba(233, 237, 245, 1);
$BG1B2: rgba(223, 226, 233, 1);
// gradients
$BG1G1: linear-gradient(135deg, $BG1A1 0%, $BG1B1 100%);
$BG1G2: linear-gradient(135deg, $BG1A2 0%, $BG1B2 100%);
// ui highlieght
$HL1: rgba(223, 226, 233, 0.5);
// box shadows
$SBG1: rgba(209, 217, 230, 0.6);
// small
$S1: -8px -4px 8px 0px $WHITE, 8px 4px 12px 0px $SBG1;
$S2: -4px -4px 8px 0px $WHITE, 4px 4px 12px 0px $SBG1;
$SI1: -4px -4px 8px $SBG1, 4px 4px 8px $BG1A1, inset -4px -4px 8px $BG1A1,
  inset 4px 4px 8px $SBG1;
$SI2: -8px -4px 12px 0px $WHITE, 8px 4px 12px 0px $SBG1,
  4px 4px 4px 0px $SBG1 inset, -4px -4px 4px 0px $WHITE inset;
$SI3: -8px -4px 12px 0px $WHITE, 8px 4px 12px 0px $SBG1,
  2px 2px 4px 0px $SBG1 inset, -2px -2px 4px 0px $WHITE inset;
// borders
$BORDER1: 2px solid $WHITE;
$BORDER7: 2px solid $GREY6;
$BORDER_RADIUS1: 1rem;
$BORDER_RADIUS2: 1.2rem;

// neumorphism dark
// gradients
$DM-BG1G1: linear-gradient(0deg, rgb(32, 32, 32) 0%, rgb(45, 45, 45) 100%);
$DM-BG1G2: linear-gradient(0deg, rgb(38, 38, 38) 0%, rgb(51, 51, 51) 100%);

// box shadows
$DM-SBG1: rgba(0, 0, 0, 0.4);
$DM-SBG2: rgba(55, 55, 55, 0.5);
$DM-SBG1-SOFT: rgba(0, 0, 0, 0.2);
$DM-SBG2-SOFT: rgba(55, 55, 55, 0.2);
// small
$DM-S1: -4px -4px 12px $DM-SBG2, 4px 4px 12px $DM-SBG1;
$DM-S1-SOFT: -4px -4px 8px $DM-SBG2-SOFT, 4px 4px 8px $DM-SBG1-SOFT;
$DM-SI1: -4px -4px 8px $DM-SBG1, 4px 4px 8px $DM-SBG2,
  inset -4px -4px 8px $DM-SBG2, inset 4px 4px 8px $DM-SBG1;
$DM-SI2: -8px -4px 8px 0px $DM-SBG2, 8px 4px 12px 0px $DM-SBG1,
  4px 4px 4px 0px $DM-SBG1 inset, -4px -4px 4px 0px $DM-SBG2 inset;
// borders
$DM-BORDER1: 2px solid $GREY5;

// transitions
$TRANSITION1: all 0.3s ease-in-out;

// ui element size
$UISIZE1: 1.2rem;
$UISIZE2: 1.4rem;
$UISIZE3: 2.8rem;
$UIRADIUS1: 0.4rem;
$OPACITYLOW: 0.35;
$OPACITYFULL: 1;

// layout
* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
.pageContainer {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  background: $BG1G1;
}
.pageContainerDM {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  background: $DM-BG1G1;
}
.pageContainer-sub {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  min-height: 100vh;
}
.pageContainer-subDM {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  min-height: 100vh;
  background: $DM-BG1G1;
}
@media (max-width: 1023px) {
  .pageContainer,
  .pageContainerDM,
  .pageContainer-sub,
  .pageContainer-subDM {
    display: grid;
    grid-template-columns: auto;
  }
}
.contentContainerBasic {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 600px auto;
  padding: 5rem;
  position: relative;
  z-index: 900;
}
.contentContainerBasicDM {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 600px auto;
  padding: 5rem;
  position: relative;
  z-index: 1000;
}
.contentContainerBlock {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 350px auto;
  padding: 5rem;
  background: $BG1G1;
  position: relative;
  z-index: 900;
}
.contentContainerBlockDM {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 350px auto;
  padding: 5rem;
  background: $DM-BG1G1;
  position: relative;
  z-index: 1000;
}
.contentContainerComplete {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 600px auto;
  padding: 5rem;
  background: $BG1G1;
  position: relative;
  z-index: 900;
}
.contentContainerCompleteDM {
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: auto 600px auto;
  padding: 5rem;
  background: $DM-BG1G1;
  position: relative;
  z-index: 1000;
}
@media (max-width: 1023px) {
  .contentContainer,
  .contentContainerDM {
    grid-template-columns: auto;
    padding: 2rem;
  }
}
.TypographyContent {
  grid-column-start: 2;
  grid-column-end: 3;
  overflow: hidden;
}
@media (max-width: 1023px) {
  #typographyContentContainer {
    display: block;
    width: 100vw;
    padding: 2rem;
  }
}
.sideBarContainer {
  height: inherit;
  width: 20rem;
  position: relative;
  padding: 2rem;
  background: $BG1G1;
  text-align: left;
  box-shadow: $S1;
  z-index: 1000;
}
.sideBarContainerDM {
  height: inherit;
  width: 20rem;
  position: relative;
  padding: 2rem;
  background: $DM-BG1G1;
  text-align: left;
  box-shadow: $DM-S1-SOFT;
  z-index: 1010;
}
@media (max-width: 1023px) {
  .sideBarContainer {
    width: 100vw;
  }
  .sideBarContainerDM {
    width: 100vw;
  }
}
.flexColumn {
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
}
.flexRow {
  display: flex;
  flex-direction: row;
  margin-top: 0.4rem;
}
.headerContainer {
  font-family: Inter, HelveticaNeue-Light, 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  letter-spacing: 0.05rem;
  display: flex;
  justify-content: space-between;
  margin: 0rem 0rem 1rem;
}

// header
.logoWhite {
  width: 6rem;
}
.navBarCredits {
  color: #fff;
  font-size: 0.7rem;
  padding-top: 0.3rem;
}
.navBarCreditsLink {
  text-decoration: none;
  font-weight: 900;
  color: rgb(255, 255, 255);
}
.navSidebarItem.active {
  opacity: 1;
}
.copywrightIcon {
  width: 0.9rem;
  position: relative;
  top: 3.3px;
  left: -2px;
}
.burgerMenuCont {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.6rem;
  background: $BG1G1;
  box-shadow: $S1;
  cursor: pointer;
}
.burgerMenuContDM {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  border-radius: 0.6rem;
  background: $DM-BG1G1;
  box-shadow: $DM-S1;
  cursor: pointer;
}
.darkmodeSun {
  position: absolute;
  width: 1.2rem;
  left: 0.2rem;
  top: 0.2rem;
}
.darkmodeMoon {
  position: absolute;
  width: 1.2rem;
  top: 0.15rem;
  left: 1.7rem;
}

// sidebar
.logoBlack {
  width: 6rem;
}
.navContainer {
  transform: translateX(-22rem);
  position: fixed;
  top: 0px;
  z-index: 9998;
  width: 18rem;
  height: 100vh;
}
.nav {
  box-sizing: border-box;
  height: 100vh;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 3rem 2rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  background: $DM-BG1G1;
  z-index: 9999;
  box-shadow: $DM-S1-SOFT;
}
.navSidebarItemContainer {
  padding-bottom: 1rem;
}
.navSidebarItem {
  width: 12.5rem;
  display: flex;
  align-items: center;
  font-size: $FONT_SIZE_3;
  line-height: 2rem;
  padding: 0.4rem 0rem;
  margin: 0 0 1rem 0;
  box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.1),
    4px 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: $BORDER_RADIUS2;
  transition: $TRANSITION1;
  text-decoration: none;
  opacity: 0.5;
  color: WHITE;
  &:hover {
    opacity: 1;
  }
  &:active {
    box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.2),
      4px 4px 8px rgba(255, 255, 255, 0.1),
      inset -4px -4px 8px rgba(255, 255, 255, 0.1),
      inset 4px 4px 8px rgba(0, 0, 0, 0.2);
  }
  & span {
    position: relative;
  }
}
.navSidebarItemLinkActive {
  opacity: 0.1;
}
.navSidebarItemIcon {
  width: 2.8rem;
  height: 1.8rem;
  position: relative;
  padding: 0 0.4rem 0 1rem;
}

// choose fonts sidebar
.chooseFontsSidebar {
  height: 100vh;
  width: 20rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: fixed;
  background: $BG1G1;
  z-index: 9999;
  box-sizing: border-box;
}
.chooseFontsSidebarDM {
  height: 100vh;
  width: 20rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: fixed;
  background: $DM-BG1G1;
  z-index: 9999;
  box-sizing: border-box;
}
.fontSearchBarCont {
  margin-bottom: 1rem;
  width: 67%;
}

// ui elements
.burgerMenuIcon {
  width: 1.5rem;
  cursor: pointer;
}
.checkBoxCont {
  position: relative;
  top: 2px;
  margin-left: 0.1rem;
}
.checkBoxInput {
  width: $UISIZE2;
  height: $UISIZE2;
  position: relative;
  margin-top: 3px;
  margin-right: 8px;
  top: -1px;
  background: $BG1G1;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  box-shadow: $S1;
  border: none;
  border-radius: $UIRADIUS1;
  transition: $TRANSITION1;
}
.checkBoxInputDM {
  width: $UISIZE2;
  height: $UISIZE2;
  position: relative;
  margin-top: 3px;
  margin-right: 8px;
  top: -1px;
  background: $DM-BG1G1;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  box-shadow: $DM-S1;
  border: none;
  border-radius: $UIRADIUS1;
  transition: $TRANSITION1;
}
.checkBoxLabel {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  position: absolute;
  top: 5px;
  opacity: $OPACITYLOW;
  transition: $TRANSITION1;
}
.checkBoxLabelDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  position: absolute;
  color: $WHITE;
  top: 5px;
  opacity: $OPACITYLOW;
  transition: $TRANSITION1;
}
.checkBoxLabelActive {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  position: absolute;
  top: 5px;
  transition: $TRANSITION1;
}
.checkBoxLabelActiveDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  color: $WHITE;
  position: absolute;
  top: 5px;
  top: $OPACITYFULL;
  transition: $TRANSITION1;
}
.checkBoxTick {
  width: $UISIZE2;
  height: $UISIZE2;
  position: absolute;
  top: 1px;
  left: 0;
}
// radio buttons
.radioWrapper {
  margin: 0.2rem 0;
}
.radioState {
  position: relative;
  top: 0;
  right: 0;
  opacity: 1e-5;
  pointer-events: none;
}
.radioLabel {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transform: translateX(-18px);
}
.radioText {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  color: $BLACK;
  opacity: 0.5;
  margin-left: 16px;
  user-select: none;
  transition: $TRANSITION1;
}
.radioTextDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  color: $WHITE;
  opacity: 0.5;
  margin-left: 16px;
  user-select: none;
  transition: $TRANSITION1;
}
.radioIndicator {
  position: relative;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  box-shadow: $S1;
  overflow: hidden;
}
.radioIndicatorDM {
  position: relative;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  box-shadow: $DM-S1;
  overflow: hidden;
}
.radioIndicator::before,
.radioIndicator::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border-radius: 50%;
}
.radioIndicatorDM::before,
.radioIndicatorDM::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 10%;
  height: 80%;
  width: 80%;
  border-radius: 50%;
}
.radioIndicator::before {
  box-shadow: -4px -2px 4px 0px #d1d9e6, 4px 2px 8px 0px #fff;
}
.radioIndicatorDM::before {
  box-shadow: $DM-SI2;
}
.radioIndicator::after {
  background-color: #ecf0f3;
  box-shadow: -4px -2px 4px 0px #fff, 4px 2px 8px 0px #d1d9e6;
  transform: scale3d(1, 1, 1);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}
.radioIndicatorDM::after {
  background: $DM-BG1G1;
  transform: scale3d(1, 1, 1);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}
.radioState:checked ~ .radioLabel .radioIndicator::after {
  transform: scale3d(0.975, 0.975, 1) translate3d(0, 10%, 0);
  opacity: 0;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
}
.radioState:checked ~ .radioLabel .radioIndicatorDM::after {
  transform: scale3d(0.975, 0.975, 1) translate3d(0, 10%, 0);
  opacity: 0;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
}
.radioState:focus ~ .radioLabel .radioText {
  opacity: 1;
}
.radioState:focus ~ .radioLabel .radioTextDM {
  opacity: 1;
}
.radioLabel:hover .radioText {
  opacity: 1;
}
.radioLabel:hover .radioTextDM {
  opacity: 1;
}
.radioState:checked ~ .radioLabel .radioText {
  opacity: 1;
}
.radioState:checked ~ .radioLabel .radioTextDM {
  opacity: 1;
}

// toogle switch
.toggle-switch {
  display: flex;
}
.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #394a56;
}
.toggle {
  isolation: isolate;
  position: relative;
  height: 25px;
  width: 50px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: $SI2;
}
.toggleDM {
  isolation: isolate;
  position: relative;
  height: 25px;
  width: 50px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: $DM-SI2;
}
.toggle-state {
  display: none;
}
.indicator {
  height: 100%;
  width: 200%;
  background: $BG1G1;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s ease-in-out;
  box-shadow: -8px -4px 8px 0px #ffffff, 8px 4px 12px 0px #d1d9e6;
}
.indicatorDM {
  height: 100%;
  width: 200%;
  background: $DM-BG1G1;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s ease-in-out;
  box-shadow: 0px -4px 8px 0px #000000, 8px 4px 12px 0px #444444;
}
.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}
.toggle-state:checked ~ .indicatorDM {
  transform: translate3d(25%, 0, 0);
}

// choose fonts dashboard
.dashboardSubMenu {
  border-radius: 1rem;
  background: $BG1G1;
  margin: 0 0 0.8rem;
  padding: 1rem 1.8rem 1rem;
  box-shadow: $S1;
  overflow: hidden;
}
.dashboardSubMenuDM {
  border-radius: 1rem;
  background: $DM-BG1G1;
  margin: 0 0 0.8rem;
  padding: 1rem 1.8rem 1rem;
  box-shadow: $DM-S1;
  overflow: hidden;
}
.typographyDashboardCont {
  display: flex;
  flex-direction: column;
}
.dropDownArrow {
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: -3px;
}
.chooseFontPicker {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 2rem;
  width: 105%;
  display: flex;
  position: relative;
  left: -0.6rem;
  align-items: center;
  border: $BORDER1;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  &:hover {
    box-shadow: $S1;
  }
}
.chooseFontPickerDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 2rem;
  width: 105%;
  color: $WHITE;
  display: flex;
  position: relative;
  left: -0.6rem;
  align-items: center;
  border: $DM-BORDER1;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  &:hover {
    box-shadow: $DM-S1;
  }
}
.chooseFontHeading {
  font-family: $FONT_FAMILY_REGULAR_1;
  letter-spacing: 0.05rem;
  font-size: 0.65rem;
  color: $GREY3;
  text-transform: uppercase;
  margin: 0.8rem 0px 0.2rem;
}

// choose fonts sidebar Buttons
.fontCategoryButtonContDashboard {
  display: flex;
  flex-wrap: wrap;
}
.fontCategoryButtonCont {
  width: calc(33.3333%);
  font-size: 0.75rem;
  height: 1.8rem;
  margin: 0 0.3rem 0.6rem;
  flex: 1 0 28%;
  &:nth-child(3n + 1) {
    margin-left: 0;
  }
}
.fontCategoryButton {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_REGULAR;
  width: 100%;
  height: 100%;
  border-style: none;
  outline: none;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  background: $BG1G1;
  box-shadow: $S1;
  transition: $TRANSITION1;
  &:active {
    background: $BG1G2;
    box-shadow: $SI1;
    transition: $TRANSITION1;
  }
}
.fontCategoryButtonDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_REGULAR;
  color: $WHITE;
  width: 100%;
  height: 100%;
  border-style: none;
  outline: none;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  background: $DM-BG1G1;
  box-shadow: $DM-S1;
  transition: $TRANSITION1;
  &:active {
    background: $DM-BG1G1;
    box-shadow: $DM-SI1;
    transition: $TRANSITION1;
  }
}
.fontCategoryButtonActive {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_REGULAR;
  width: 100%;
  font-size: 0.75rem;
  height: 100%;
  flex: 1 0 28%;
  border-style: none;
  outline: none;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  background: $BG1G2;
  box-shadow: $SI1;
}
.fontCategoryButtonActiveDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_REGULAR;
  color: $WHITE;
  width: 100%;
  font-size: 0.75rem;
  height: 100%;
  flex: 1 0 28%;
  border-style: none;
  outline: none;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  background: $DM-BG1G1;
  box-shadow: $DM-SI1;
}
.typographySearchbar {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 1.9rem;
  background: #f7f9fd;
  width: 100%;
  border: $BORDER1;
  outline: none;
  border-radius: $BORDER_RADIUS1;
  padding-left: 0.6rem;
  &::placeholder {
    font-family: $FONT_FAMILY_REGULAR_1;
    font-size: $FONT_SIZE_2;
    opacity: $OPACITYLOW;
  }
}
.typographySearchbarDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 1.9rem;
  color: $WHITE;
  background: $DM-BG1G1;
  width: 100%;
  border: $BORDER1;
  outline: none;
  border-radius: $BORDER_RADIUS1;
  padding-left: 0.6rem;
  &::placeholder {
    font-family: $FONT_FAMILY_REGULAR_1;
    font-size: $FONT_SIZE_2;
    opacity: $OPACITYFULL;
  }
}
.chooseFontCategoryList {
  box-sizing: border-box;
  height: 100%;
  width: auto;
  position: relative;
  padding: 1rem 1rem;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: $BORDER1;
  border-radius: $BORDER_RADIUS1;
}
.chooseFontCategoryListDM {
  box-sizing: border-box;
  height: 100%;
  width: auto;
  position: relative;
  padding: 1rem 1rem;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: $BORDER1;
  border-radius: $BORDER_RADIUS1;
}
.fontSearchBarFallback {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  position: absolute;
  z-index: -10;
}
.fontSearchBarFallbackDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  color: $WHITE;
  position: absolute;
  z-index: -10;
}
.chooseFontsSidebarDashboard {
  height: '10.9rem';
}
.sidebarBackButton {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1.2rem;
  border: $BORDER7;
  border-radius: $UIRADIUS1;
  opacity: 0.15;
  transition: $TRANSITION1;
  &:hover {
    opacity: 0.6;
    box-shadow: $S1;
  }
}
.sidebarBackButtonDM {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1.2rem;
  border: $DM-BORDER1;
  border-radius: $UIRADIUS1;
  opacity: 0.35;
  transition: $TRANSITION1;
  &:hover {
    opacity: 0.6;
    box-shadow: $DM-S1;
  }
}
.sidebarBackButtonIcon {
  width: 1.4rem;
  position: relative;
  left: -1.6px;
  top: -2px;
  margin-right: 0.25rem;
}
.fontSearchBarAndItalicCont {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.typographyFontItem:nth-of-type(1) {
  padding-top: 1rem;
}
.typographyFontItem {
  color: $FONT_COLOR_BLACK;
}
.typographyFontItemDM {
  color: $WHITE;
}

// choose layout
.dropDownButton {
  user-select: none;
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  font-family: Inter, HelveticaNeue-Light, 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
}
.dropDownButtonDM {
  user-select: none;
  color: $WHITE;
  opacity: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
  font-family: Inter, HelveticaNeue-Light, 'Helvetica Neue Light',
    'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  cursor: pointer;
}
.radioButtonLabel {
  &:hover {
    cursor: pointer;
  }
}
.radioButtonInput {
  &:hover {
    cursor: pointer;
  }
}

// // adjust sizes
// range slider
input[type='range'].inputRange {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
  margin: 0.2rem 0 0.6rem;
}
input[type='range'].inputRangeDM {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: 10px 0;
  width: 100%;
  background: transparent;
  margin: 0.2rem 0 0.6rem;
}
input[type='range'].inputRange:focus {
  outline: none;
}
input[type='range'].inputRange::-webkit-slider-runnable-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: $BG1A1;
  border-radius: 25px;
  box-shadow: $SI3;
  border: 0px solid #000101;
}
input[type='range'].inputRangeDM::-webkit-slider-runnable-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  background: $DM-BG1G1;
  border-radius: 25px;
  box-shadow: $DM-SI1;
  border: 0px solid #000101;
}
input[type='range'].inputRange::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $BG1A1;
  cursor: pointer;
  box-shadow: $S2;
  -webkit-appearance: none;
  margin-top: -3.6px;
}
input[type='range'].inputRangeDM::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $DM-BG1G2;
  cursor: pointer;
  box-shadow: $DM-S1;
  -webkit-appearance: none;
  margin-top: -3.6px;
}
.adjustSizeHeading {
  font-family: $FONT_FAMILY_REGULAR_1;
  letter-spacing: 0.05rem;
  font-size: 0.65rem;
  color: $GREY3;
  text-transform: uppercase;
  margin: 0.2rem 0px 0.3rem;
}
.SizeValueDisplay {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  letter-spacing: 0.05rem;
  font-size: 0.65rem;
  color: $BLACK;
  text-transform: uppercase;
  margin: 0.2rem 0px 0.3rem 0.2rem;
}
.SizeValueDisplayDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-weight: $FONT_WEIGHT_BOLD;
  letter-spacing: 0.05rem;
  font-size: 0.65rem;
  color: $WHITE;
  text-transform: uppercase;
  margin: 0.2rem 0px 0.3rem 0.2rem;
}
input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range'].inputRange::-moz-range-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: $BG1A1;
  border-radius: 25px;
  box-shadow: $SI3;
  border: 0px solid #000101;
}
input[type='range'].inputRangeDM::-moz-range-track {
  width: 100%;
  height: 12.8px;
  cursor: pointer;
  background: $DM-BG1G1;
  border-radius: 25px;
  box-shadow: $DM-SI1;
  border: 0px solid #000101;
}
input[type='range'].inputRange::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $BG1A1;
  cursor: pointer;
  box-shadow: $S2;
  -webkit-appearance: none;
  margin-top: -3.6px;
}
input[type='range'].inputRangeDM::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: $DM-BG1G2;
  cursor: pointer;
  box-shadow: $DM-S1;
  -webkit-appearance: none;
  margin-top: -3.6px;
}

// // adjust alignment
.alignmentButtonCont {
  width: calc(33.3333%);
  font-size: 0.75rem;
  height: 1.8rem;
  margin: 0 0.3rem 0.6rem;
  flex: 1 0 28%;
  &:nth-child(3n + 1) {
    margin-left: 0;
  }
}
.alginmentIcon {
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 1px;
}
.fontCategoryButtonActive svg {
  transform: scale(0.9);
  transition: $TRANSITION1;
}

// // font download
.fontDownloadDisplay {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 2rem;
  width: 105%;
  display: flex;
  position: relative;
  left: -0.6rem;
  align-items: center;
  border: $BORDER1;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  &:hover {
    box-shadow: $S1;
  }
}
.fontDownloadDisplayDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 2rem;
  width: 105%;
  color: $WHITE;
  display: flex;
  position: relative;
  left: -0.6rem;
  align-items: center;
  border: $DM-BORDER1;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  &:hover {
    box-shadow: $DM-S1;
  }
}
.downloadIcon {
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: -1px;
  left: 5px;
}

// export CSS
.cssFilenameInputCont {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  height: 2rem;
  width: 105%;
  display: flex;
  position: relative;
  left: -0.6rem;
  align-items: center;
  border: $BORDER1;
  padding: 0rem 0rem 0rem 0.5rem;
  cursor: pointer;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
  &:hover {
    box-shadow: $S1;
  }
  & #cssFileNameInput {
    max-width: 19ch;
  }
}
.cssFileNameExt {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  background: none;
  border: none;
}
.cssFileNameExtDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  font-size: $FONT_SIZE_2;
  color: $WHITE;
  background: none;
  border: none;
}

// typography display
.typographyDisplayItemDM {
  color: $WHITE;
  margin-top: 0;
}
.typographyDisplayItem {
  margin-top: 0;
}
// sub pages
.subPageContentContainer {
  font-family: $FONT_FAMILY_REGULAR_1;
  color: $GREY6;
  padding: 5rem 10rem;
}
.subPageContentContainerDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  color: $WHITE;
  padding: 5rem 10rem;
}
.subPageHeading {
  font-family: $FONT_FAMILY_REGULAR_1;
  color: $GREY6;
}
.subPageHeadingDM {
  font-family: $FONT_FAMILY_REGULAR_1;
  color: $WHITE;
}
.subPageContentContainer h2 {
  font-size: 2rem;
  margin-top: 0.5rem;
}
.subPageContentContainerDM h2 {
  font-size: 2rem;
  margin-top: 0.5rem;
}
.subPageContentContainer p {
  font-size: 1.3rem;
  line-height: 2rem;
}
.subPageContentContainerDM p {
  font-size: 1.3rem;
  line-height: 2rem;
}
.subPageContentContainer a:link,
.subPageContentContainer a:visited {
  color: $GREY6;
  text-decoration: none;
  font-weight: $FONT_WEIGHT_BOLD;
  transition: $TRANSITION1;
}
.subPageContentContainerDM a:link,
.subPageContentContainerDM a:visited {
  color: $WHITE;
  text-decoration: none;
  font-weight: $FONT_WEIGHT_BOLD;
  transition: $TRANSITION1;
}
.subPageContentContainer a:hover {
  opacity: 0.8;
}
.subPageContentContainerDM a:hover {
  opacity: 0.8;
}

// loading screen
.lSPageCont {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: auto minmax(300px, 30vw) auto;
}
.lSPageContent {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $FONT_FAMILY_REGULAR_1;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.lSPageContent a {
  text-decoration: none;
  font-weight: $FONT_WEIGHT_BOLD;
  color: $BLACK;
  transition: $TRANSITION1;
}
.lSPageContent a:hover {
  opacity: 0.8;
}
#loadingMessage {
  font-weight: $FONT_WEIGHT_REGULAR;
  user-select: none;
  line-height: 1.6rem;
  font-size: 1.2rem;
}
.lSPageContent .fontCategoryButton {
  padding: 0.6rem 2rem;
  text-transform: uppercase;
}
.enterButton {
  margin-left: 1.2rem;
}
.remove-btn {
  margin-right: 0.5rem;
}
.mobileDataWarning {
  font-weight: $FONT_WEIGHT_BOLD;
  margin: 0 0 1.2rem 0;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

// animated Circle
.circle {
  position: relative;
  left: -1rem;
  top: -2rem;
  grid-column: 2 / 3;
  grid-row: 4 / 6;
  width: 18rem;
  height: 18rem;
  justify-self: center;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  &__btn {
    animation: waves2 2s linear infinite;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 18rem;
    height: 18rem;
    display: flex;
    margin: 0.6rem;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.2rem;
    font-family: $FONT_FAMILY_REGULAR_1;
    color: $GREY4;
    text-align: center;
    z-index: 300;
    background: $BG1G1;
    box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #ffffff;
    position: relative;
    &.shadow {
      box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7,
        inset -0.2rem -0.2rem 0.5rem #ffffff;
    }
    .play {
      position: absolute;
      opacity: 0;
      transition: all 0.2s linear;
      &.visibility {
        opacity: 1;
      }
    }
    .pause {
      position: absolute;
      transition: all 0.2s linear;
      &.visibility {
        opacity: 0;
      }
    }
  }
  &__back-1,
  &__back-2 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 18rem;
    height: 18rem;
    border-radius: 50%;
    filter: blur(1px);
    z-index: 100;
  }
  &__back-1 {
    box-shadow: 0.4rem 0.4rem 0.8rem #c8d0e7, -0.4rem -0.4rem 0.8rem #ffffff;
    background: linear-gradient(to bottom right, #c8d0e7 0%, #ffffff 100%);
    animation: waves 4s linear infinite;

    &.paused {
      animation-play-state: paused;
    }
  }
  &__back-2 {
    box-shadow: 0.4rem 0.4rem 0.8rem #c8d0e7, -0.4rem -0.4rem 0.8rem #ffffff;
    animation: waves 4s linear 2s infinite;

    &.paused {
      animation-play-state: paused;
    }
  }
}
@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes waves2 {
  0% {
    transform: scale(1.04);
  }
  25% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}

// google Adsense
.googleAdsenseCont {
  background: $BG1G1;
  box-shadow: $S1;
  z-index: 9999;
  min-height: 100vh;
}
.googleAdsenseContDM {
  background: $DM-BG1G1;
  box-shadow: $DM-S1-SOFT;
  z-index: 9999;
  min-height: 100vh;
}

// Utilities
*:focus {
  outline: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: $BG1B2;
  border: 0px none #ffffff;
  min-height: 5rem;
  border-radius: $BORDER_RADIUS1;
  transition: $TRANSITION1;
}
::-webkit-scrollbar-thumb:hover {
  background: $GREY2;
  transition: $TRANSITION1;
}
::-webkit-scrollbar-thumb:active {
  background: $GREY3;
  transition: $TRANSITION1;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
